<template>
  <div style="padding-top: 30px">
    <a-tag style="padding: 5px 15px; border-radius: 8px"
      >当前订单:
      <span style="font-size: 20px; color: rgb(16, 142, 233)">{{
        orderSn
      }}</span></a-tag
    >
    <div style="clear: both; padding-top: 50px">
      <h2 style="padding-bottom: 20px">订单进度</h2>
      <van-circle  layer-color="#ccc" v-model="progressData" :rate="progressData" :speed="100" :text="progressData + '%'" size="300"/>
    </div>
  </div>
</template>
<script>
export default {
  name: "about",
  data() {
    return {
      params: {
        typeId: "",
      },
      orderSn: "",
      progressData: 0,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let res = await this.$Http.mesCommandOrder();
      if(res["RtnData"]) {
        this.orderSn = res["RtnData"][2]["ItemValue"];
        this.progressData = Number(res["RtnData"][3]["ItemValue"]);
      }
    },
    async afterRead(file) {
      // 此时可以自行将文件上传至服务器
      const formData = new FormData();
      formData.append("file", file.file);
      let res = await this.$Http.upload(formData);
      this.fileName = "http://192.168.0.100/upload/" + res["fdown"];
    },
  },
};
</script>
<style lang="less" scoped></style>
